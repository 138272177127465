import Head from "next/head";
import Layout, { siteTitle } from "../components/layout";

export default function Home() {
  return (
    <Layout home>
      <Head>
        <title>{`${siteTitle} 404`}</title>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#000000"
        />
        <link rel="shortcut icon" href="/favicons/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-config"
          content="/favicons/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff"></meta>

        <meta name="title" content="Kaleidoscope Agency" />
        <meta
          name="description"
          content="Kaleidoscope agency is constantly looking for new faces and recruits actors, models, athletes, atypical profiles and other people/face profiles."
        />
        <meta
          name="keywords"
          content="kaleidoscope-agency,kaleidoscope,agency,model,new,faces,recruits,actors,actor,model,athletes,athlete,atypical,profile,profiles,women,woman,men,man,tv,fiction,pub,"
        />
        <meta name="robots" content="index, follow" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="author" content="Virginie Miesse" />
      </Head>

      <section>
        <p
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          Error 404
        </p>
      </section>
    </Layout>
  );
}
